import React from 'react'

const SampleAdvantages = ({elem}) => {
  return (
	<div className="section">
		<div className="container-fluid px-0">
			<div className="row no-gutters">
				<div className="col-xl-6 bg-grey">
					<div className="max-670 mx-lg-auto px-15">
						<div className="title-wrap">
							<h2 className="h1" dangerouslySetInnerHTML={{__html: elem.title}}></h2>
						</div>
						<div className="mt-lg-5"></div>
						{elem.elements?.length > 7 ?
                            <div className="row">
                                <div className="col-sm-7">
                                    <ul className="marker-list-md">
                                        {elem.elements.slice(0, 7).map((el, index) =>
                                            <li key={index}>{el.title}</li>
                                        )}
                                    </ul>
                                </div>
                                <div className="col-sm-5 mt-1 mt-sm-0">
                                    <ul className="marker-list-md">
                                        {elem.elements.slice(7, 14).map((el, index) =>
                                            <li key={index}>{el.title}</li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                            :
                            <div className='row'>
                                <ul className="marker-list-md">
                                    {elem.elements.map((el, index) =>
                                        <li key={index}>{el.title}</li>
                                    )}
                                </ul>
                            </div>
                        }
					</div>
				</div>
				<div className="col-xl-6 banner-left bg-full" style={{backgroundImage: `url(${elem.steps_image})`, backgroundSize: 'cover'}}>
                    
                </div>
			</div>
		</div>
	</div>
  )
}

export default SampleAdvantages