import React, { useEffect } from 'react'
import { useLangContext } from '../../i18n/ProvideLang';
import { useNavigate, useParams } from 'react-router-dom';
import useFetchItems from '../../hooks/useFetchItems';
import AppServices from '../../services/general/appServices';
import MainBanner from '../../components/complex/MainBanner';
import About from '../../components/complex/About';
import Steps from '../../components/complex/Steps';
import Faq from '../../components/complex/Faq';
import AdvertisingBanner from '../../components/complex/AdvertisingBanner';
import Advantages from '../../components/complex/Advantages';
import MainServices from '../../components/complex/MainServices';
import Reviews from '../../components/complex/Reviews';
import Gallery from '../../components/complex/Gallery';
import SpecialOffers from '../../components/complex/SpecialOffers';
import { Helmet } from 'react-helmet';
import SampleForm from '../../components/complex/SampleForm';
import Workers from '../../components/complex/Workers';
import Packages from '../../components/complex/Packages';
import TextComponent from '../../components/complex/TextComponent';
import VideoComponent from '../../components/complex/VideoComponent';

const MainPage = () => {
    const navigate = useNavigate();
    const {lang} = useLangContext();
    const {lang: lang2} = useParams();
  
    useEffect(() => {
        if(lang){
            if(lang !== lang2) {
                navigate(`/${lang}`)
            }
        }
    }, [lang])

    const {
        items,
        loading,
    } = useFetchItems(AppServices.page, 'main')

  return (
    <>
        {loading ?
        <div className="loading-content">
            <div className="inner-circles-loader"></div>
        </div>
        :
        <div className='page-content'>
            <Helmet>
                <title>{items.seo_title ? items.seo_title : items.title}</title>
                {items.seo_description && <meta name="description" content={items.seo_description} />}
                {items.seo_keywords && <meta name="Keywords" content={items.seo_keywords}/>}
            </Helmet>
            {items.elements?.map((elem, index) => 
                <div key={index}>
                    {                
                    elem.type === "opening-slider" ?
                        <MainBanner elem={elem}/>
                    :
                    elem.type === "about" ?
                        <About elem={elem}/>
                    :
                    elem.type === "steps" ?
                        <Steps elem={elem}/>
                    :
                    elem.type === "advertising-banner-2" ?
                        Number(elem.banner_type) === 2 ?
                            <AdvertisingBanner elem={elem}/>
                            :
                            <></>
                    :
                    elem.type === "faq" ?
                        <Faq elem={elem}/>
                    :
                    elem.type === "services-2" ?
                        <MainServices elem={elem}/>
                    :
                    elem.type === "advantages" ?
                        <Advantages elem={elem}/>
                    :
                    elem.type === "reviews" ?
                        <Reviews elem={elem}/>
                    :
                    elem.type === "gallery" ?
                        <Gallery elem={elem}/>
                    :
                    elem.type === "advantages-2" ?
                        <SpecialOffers elem={elem}/>
                    :
                    elem.type === "form" ?
                        <SampleForm elem={elem}/>
                    :
                    elem.type === "workers" ?
                        <Workers elem={elem}/>
                    :
                    elem.type === "packages" ?
                        <Packages elem={elem}/>
                    :
                    elem.type === "text-information" ?
                        <TextComponent elem={elem}/>
                    :
                    elem.type === "video-component" ?
                        <VideoComponent elem={elem}/>
                    :
                        <></>
                    }
                </div>
            )}
        </div>
        }
    </>
  )
}

export default MainPage