import React, { useEffect, useState } from 'react'

const AdvertisingBanner = ({elem}) => {
    const [banner, setBanner] = useState();

    useEffect(() => {
        if(elem){
            setBanner(elem.elements[0])
        }
    }, [elem])

  return (
    banner &&
    <div className="section mt-7">
        <div className="container">
            <div className="banner-call">
                <div className="row no-gutters">
                    <div className="col-sm-5 col-lg-5 order-2 order-sm-1 mt-3 mt-md-0 text-center text-md-right">
                        <img src={banner.image} alt="" className="shift-left"/>
                    </div>
                    <div className="col-sm-7 col-lg-7 d-flex align-items-center order-1 order-sm-2">
                        <div className="text-center">
                            <h2 dangerouslySetInnerHTML={{__html: banner.title}}></h2>
                            <div className="h-decor"></div>
                            <p className="mt-sm-1 mt-lg-4 text-left text-sm-center" dangerouslySetInnerHTML={{__html: banner.description}}></p>
                            {banner.subtitle && <div className="mt-3 mt-lg-4">
                                <a href={`tel:${banner.subtitle}`} className="banner-call-phone"><i className="icon-telephone"></i>{banner.subtitle}</a>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdvertisingBanner