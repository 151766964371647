import React from 'react'
import Fancybox from './FancyBox'

const VideoComponent = ({elem}) => {
  return (
	<div className="section page-content-first text-component">
		<div className="container">
			<div className="text-center mb-2  mb-md-3 mb-lg-4">
				{elem.subtitle && <div className="h-sub theme-color">{elem.subtitle}</div>}
				{elem.title && <h1>{elem.title}</h1>}
				{elem.title && <div className="h-decor"></div>}
			</div>
		</div>
		<div className="container">
                <Fancybox
                    options={{
                        Carousel: {
                        infinite: false,
                        },
                    }}
                >
                    <div className="row">
                        {elem.elements?.map((card, index) =>
                            <a href={card.url} data-fancybox="gallery" className='col-md-6 col-xl-4' key={index}>
                                <div className='video-item'>
                                    <img src={card.image} alt="" />
                                    <span className='video-span'>
                                        <i className='icon-play'></i>
                                    </span>
                                </div>
                            </a>
                        )}
                    </div>
                </Fancybox>
        </div>
    </div>
  )
}

export default VideoComponent