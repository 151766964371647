import React from 'react'
import TextComponent from './TextComponent'
import SampleForm from './SampleForm'
import Packages from './Packages'
import Workers from './Workers'
import Gallery from './Gallery'
import Reviews from './Reviews'
import Advantages from './Advantages'
import SampleAdvantages from './SampleAdvantages'
import SampleAdvantages2 from './SampleAdvantages2'
import SpecialOffers from './SpecialOffers'
import AdvertisingBanner2 from './AdvertisingBanner2'
import AdvertisingBanner from './AdvertisingBanner'
import Steps from './Steps'
import About from './About'
import About2 from './About2'
import MainBanner from './MainBanner'
import Faq from './Faq'
import VideoComponent from './VideoComponent'

const ServiceSampleContainer = ({items}) => {
  return (
    <>
        <div className="page-content">
            {items?.elements?.map((elem, index) => 
                <div key={index}>
                    {      
                    elem.type === "opening-slider" ?
                        <MainBanner elem={elem}/>
                    :          
                    elem.type === "about-2" ?
                        <About2 elem={elem}/>
                    :
                    elem.type === "about" ?
                        <About elem={elem}/>
                    :
                    elem.type === "steps" ?
                        <Steps elem={elem}/>
                    :
                    elem.type === "advertising-banner-2" ?
                        Number(elem.banner_type) === 2 ?
                            <AdvertisingBanner elem={elem}/>
                            :
                        Number(elem.banner_type) === 3 ?
                            <AdvertisingBanner2 elem={elem}/>
                            :
                            <></>
                    :
                    elem.type === "faq" ?
                        <Faq elem={elem}/>
                    :
                    elem.type === "advantages-2" ?
                        elem.is_component ?
                            <SampleAdvantages2 elem={elem}/>
                            :
                            <SpecialOffers elem={elem}/>
                    :
                    elem.type === "advantages" ?
                        elem.is_component ?
                            <SampleAdvantages elem={elem}/>
                            :
                            <Advantages elem={elem}/>
                    :
                    elem.type === "reviews" ?
                        <Reviews elem={elem}/>
                    :
                    elem.type === "gallery" ?
                        <Gallery elem={elem}/>
                    :
                    elem.type === "workers" ?
                        <Workers elem={elem}/>
                    :
                    elem.type === "packages" ?
                        <Packages elem={elem}/>
                    :
                    elem.type === "form" ?
                        <SampleForm elem={elem}/>
                    :
                    elem.type === "text-information" ?
                        <TextComponent elem={elem}/>
                    :
                    elem.type === "video-component" ?
                        <VideoComponent elem={elem}/>
                    :
                        <></>
                    }
                </div>
            )}
        </div>
    </>
  )
}

export default ServiceSampleContainer