import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import DropdownLanguages from './DropdownLanguages';

const TopBar = ({item}) => {
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();
    const [address, setAddress] = useState();
    const [socials, setSocials] = useState();

    useEffect(() => {
        if(item){
            setPhone(item.find(it => it.type === "phone")?.value);
            setEmail(item.find(it => it.type === "email")?.value);
            setAddress(item.find(it => it.type === "address")?.value);
            setSocials(item.find(it => it.type === "socials")?.value);
        }
    }, [item])

  return (
    <div className="header-topline d-none d-lg-flex">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-auto d-flex align-items-center">
                    {phone && <div className="header-phone"><i className="icon-telephone"></i>
                        {phone.map((ph, index) =>
                            <a href={`tel:${ph}`} key={index}>{ph}</a>
                        )}
                    </div>}
                    {address && <div className="header-info"><i className="icon-placeholder2"></i>
                        {address.map((ad, index) =>
                            <span key={index}>{ad}</span>
                        )}
                    </div>}
                    {email && <div className="header-info"><i className="icon-black-envelope"></i>
                        {email.map((em, index) =>
                            <a href={`mailto:${em}`} key={index}>{em}</a>
                        )}
                    </div>}
                </div>
                <div className="col-auto ml-auto d-flex align-items-center">
                    {socials && 
                        <span className="header-social">
                                {socials?.map((sc, index) =>
                                sc.value &&
                                    sc.type === "tiktok" ?
                                    <NavLink className='svg-a' key={index} to={sc.value} target='_blank'>
                                        <i className='icon-'>
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="22" height="22" rx="11" fill="white"/>
                                                <path className='fill-a' d="M13.5582 4L10.9932 4V12.8551C10.9932 13.9102 10.0068 14.7768 8.77909 14.7768C7.55143 14.7768 6.56493 13.9102 6.56493 12.8551C6.56493 11.8188 7.52951 10.971 8.71334 10.9333V8.71015C6.10455 8.74782 4 10.5754 4 12.8551C4 15.1536 6.1484 17 8.80103 17C11.4536 17 13.602 15.1348 13.602 12.8551V8.31448C14.5666 8.91739 15.7504 9.27536 17 9.29421V7.07102C15.0708 7.01449 13.5582 5.65797 13.5582 4Z" fill="#FC2323"/>
                                            </svg>
                                        </i>
                                    </NavLink>
                                    :
                                    sc.type === "telegram" ?
                                        <NavLink className='svg-a' key={index} to={sc.value} target='_blank'>
                                            <i className='icon-'>
                                                <svg width="22" height="22" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_318_75)">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM24.8601 17.7179C22.5257 18.6888 17.8603 20.6984 10.8638 23.7466C9.72766 24.1984 9.13251 24.6404 9.07834 25.0726C8.98677 25.803 9.90142 26.0906 11.1469 26.4822C11.3164 26.5355 11.4919 26.5907 11.6719 26.6492C12.8973 27.0475 14.5457 27.5135 15.4026 27.5321C16.1799 27.5489 17.0475 27.2284 18.0053 26.5707C24.5423 22.158 27.9168 19.9276 28.1286 19.8795C28.2781 19.8456 28.4852 19.803 28.6255 19.9277C28.7659 20.0524 28.7521 20.2886 28.7372 20.352C28.6466 20.7383 25.0562 24.0762 23.1982 25.8036C22.619 26.3421 22.2081 26.724 22.1242 26.8113C21.936 27.0067 21.7443 27.1915 21.56 27.3692C20.4215 28.4667 19.5678 29.2896 21.6072 30.6336C22.5873 31.2794 23.3715 31.8135 24.1539 32.3463C25.0084 32.9282 25.8606 33.5085 26.9632 34.2313C27.2442 34.4155 27.5125 34.6068 27.7738 34.7931C28.7681 35.5019 29.6615 36.1388 30.7652 36.0373C31.4065 35.9782 32.0689 35.3752 32.4053 33.5767C33.2004 29.3263 34.7633 20.1169 35.1244 16.3219C35.1561 15.9895 35.1163 15.5639 35.0843 15.3771C35.0523 15.1904 34.9855 14.9242 34.7427 14.7272C34.4552 14.4939 34.0113 14.4447 33.8127 14.4482C32.91 14.4641 31.5251 14.9456 24.8601 17.7179Z" fill="white"/>
                                                    </g>
                                                    <defs>
                                                    <clipPath id="clip0_318_75">
                                                    <rect width="48" height="48" fill="white"/>
                                                    </clipPath>
                                                    </defs>
                                                </svg>
                                            </i>
                                        </NavLink>
                                    :
                                    <NavLink to={sc.value} className="hovicon" key={index}>
                                        <i className={
                                            sc.type === "facebook" ? "icon-facebook-logo-circle"
                                            :
                                            sc.type === "instagram" ? "icon-instagram-circle"
                                            :
                                            sc.type === "twitter" ? "icon-twitter-logo-circle"
                                            :
                                            "icon-facebook-logo-circle"
                                            }
                                        ></i>
                                    </NavLink>
                                )}
                        </span>
                    }
                    <DropdownLanguages/>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TopBar