import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useLangContext } from '../../i18n/ProvideLang';

const DropdownLanguages = () => {
    const {lang, changeLanguage, localizationsItems, languages} = useLangContext();

  return (
    <div className='languages-dropdown'>
        {languages?.length > 1 && 
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                    {lang}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {languages?.map((lang, index) =>
                        <Dropdown.Item key={index} onClick={() => changeLanguage(lang.slug)}>
                            {lang.title}
                        </Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        }
    </div>
  )
}

export default DropdownLanguages